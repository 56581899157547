import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, EnvironmentProviders, importProvidersFrom, makeEnvironmentProviders, Provider } from '@angular/core';
import { KeycloakAngularModule, KeycloakBearerInterceptor, KeycloakOptions, KeycloakService } from 'keycloak-angular';
import { KeycloakConfig } from 'keycloak-js';
import { APP_KEYCLOAK_CONFIG } from '../config/auth-keycloak-config-services';
import { AppKeycloakConfig } from '../config/auth-keycloak-config';

//import { AppKeycloakConfig } from './keycloak-config.interface';

const Keycloak = typeof window !== 'undefined' ? import('keycloak-js') : null;


// function initializeKeycloak(keycloak: KeycloakService, keycloakConfig: KeycloakConfig) {
//   if (Keycloak !== null) {
//   return () =>
//     keycloak.init({
//       config: keycloakConfig,
//       loadUserProfileAtStartUp: true, 
//       initOptions: {
//         onLoad: 'check-sso',
//         silentCheckSsoRedirectUri:
//           window.location.origin + '/assets/silent-check-sso.html'
//       }
//     });
//   } else {
//     return () => {
//       return new Promise<Boolean>((resolve, reject) => {
//         resolve(true);
//       });
//     };
//   }
// }

// function initializeKeycloak(keycloak: KeycloakService, keycloakConfig: KeycloakConfig) {
//     if (Keycloak !== null) {
//     return () =>
//       keycloak.init({
//         config: keycloakConfig,
//         loadUserProfileAtStartUp: true, 
//         initOptions: {
//           onLoad: 'check-sso',
//           silentCheckSsoRedirectUri:
//             window.location.origin + '/assets/silent-check-sso.html'
//         }
//       });
//     } else {
//       return () => {
//         return new Promise<Boolean>((resolve, reject) => {
//           resolve(true);
//         });
//       };
//     }
//   }


// Provider for Keycloak Bearer Interceptor
const KeycloakBearerInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: KeycloakBearerInterceptor,
  multi: true
};

// Provider for Keycloak Initialization
export const KeycloakInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeKeycloak,
  multi: true,
  deps: [KeycloakService]
}

// export const provideAuth = [
//     importProvidersFrom(KeycloakAngularModule),
//     {
//       provide: APP_INITIALIZER,APP_KEYCLOAK_CONFIG,
//       useFactory: initializeKeycloak,
//       multi: true,
//       deps: [KeycloakService],
//     },
//   ];

// export function provideAuth(config: KeycloakConfig): Provider[] {
//     return [
//     //  importProvidersFrom(KeycloakAngularModule),
//       {
//         provide: APP_INITIALIZER,
//         useFactory: initializeKeycloak,
//         multi: true,
//         deps: [KeycloakService, config],
//       },
//     ];
//   }


// export function provideAuth(config: KeycloakOptions): EnvironmentProviders {
//     return makeEnvironmentProviders([
//       importProvidersFrom(KeycloakAngularModule),
//       {
//         provide: APP_INITIALIZER,
//         useFactory: initializeKeycloak,
//         multi: true,
//         deps: [KeycloakService],
//       },
//     ]);
//   }


function initializeKeycloak(keycloak: KeycloakService, config: AppKeycloakConfig) {
  if (Keycloak !== null) {
    return () => keycloak.init({
      config: config.config,
      loadUserProfileAtStartUp: true,
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: `${window.location.origin}/assets/silent-check-sso.html`,
        checkLoginIframe: false
      },
      shouldAddToken: (request) => {
        const { method, url } = request;
    
        const isGetRequest = 'GET' === method.toUpperCase();
        const acceptablePaths = ['/assets', '/clients/public'];
        const isAcceptablePathMatch = acceptablePaths.some((path) =>
          url.includes(path)
        );
    
        return !(isGetRequest && isAcceptablePathMatch);
      }
    });
    
  } else {
    return () => {
      return new Promise<Boolean>((resolve) => resolve(false));
    };
  }
}


// libs/auth/src/lib/provide-auth.ts

export function provideAuth(config: AppKeycloakConfig): EnvironmentProviders {
  return makeEnvironmentProviders([
    importProvidersFrom(KeycloakAngularModule),
    {
      provide: APP_KEYCLOAK_CONFIG,
      useValue: config,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, APP_KEYCLOAK_CONFIG],
    },
  ]);
}

// keycloak.keycloakEvents$.subscribe({
//   next(event) {
//     if (event.type == KeycloakEventType.OnTokenExpired) {
//       keycloak.updateToken(20);
//     }
//   }
// });


// export function provideAuth(config: AppKeycloakConfig): EnvironmentProviders {
//   return makeEnvironmentProviders([
//     importProvidersFrom(KeycloakAngularModule),
//     {
//       provide: APP_INITIALIZER,
//       useFactory: () => initializeKeycloak(new KeycloakService(), config),
//       multi: true,
//       deps: [KeycloakService],
//     },
//   ]);
// }