import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  isDevMode,
  Provider,
  provideZoneChangeDetection,
} from '@angular/core';
import {
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  provideRouter,
  withComponentInputBinding,
  withDebugTracing,
  withEnabledBlockingInitialNavigation,
  withHashLocation,
  withInMemoryScrolling,
  withViewTransitions,
} from '@angular/router';
import { appRoutes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideStore } from '@ngrx/store';
import { metaReducers } from './debug-meta-reducers';
import { provideEffects } from '@ngrx/effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { KeycloakBearerInterceptor, KeycloakService } from 'keycloak-angular';
import keycloakConfig from '../environments/keycloak.config';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';

import { APP_CONFIG } from './AppConfig/appconfig.service';
import { DOCUMENT } from '@angular/common';
import { CustomErrorHandler } from './_helpers/custom-error-handler';
import { GlobalHttpErrorHandler } from './_helpers/global-http-error-handler.interceptor';
import { APP_SERVICE_CONFIG } from '@ushauri/shared-config';
import {
  AuthGuard,
  canActivateGuard,
  KeycloakInitializerProvider,
  provideAuth,
} from '@ushauri/auth';
import { APP_KEYCLOAK_CONFIG } from 'libs/auth/src/lib/auth/config/auth-keycloak-config-services';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

/*const Keycloak = typeof window !== 'undefined' ? import('keycloak-js') : null;


function initializeKeycloak(keycloak: KeycloakService) {
  if (Keycloak !== null) {
  return () =>
    keycloak.init({
      config: keycloakConfig,
      loadUserProfileAtStartUp: true, 
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html'
      }
    });
  } else {
    return () => {
      return new Promise<Boolean>((resolve, reject) => {
        resolve(true);
      });
    };
  }
}


// Provider for Keycloak Bearer Interceptor
const KeycloakBearerInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: KeycloakBearerInterceptor,
  multi: true
};

// Provider for Keycloak Initialization
const KeycloakInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeKeycloak,
  multi: true,
  deps: [KeycloakService]
}
*/

const appKeycloakConfig = {
  config: keycloakConfig,
};

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {
  providers: [
    provideEffects(),
    provideStore(),
    provideClientHydration(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()), // Provides HttpClient with interceptors
    // KeycloakInitializerProvider, // Initializes Keycloak
    // KeycloakBearerInterceptorProvider, // Provides Keycloak Bearer Interceptor
    //KeycloakService, // Service for Keycloak
    //// KeycloakInitializerProvider,
    KeycloakBearerInterceptor,
    AuthGuard,
    { provide: APP_KEYCLOAK_CONFIG, useValue: keycloakConfig },
    // provideAuth(keycloakConfig),
    provideAuth(appKeycloakConfig),
    { provide: APP_SERVICE_CONFIG, useValue: APP_CONFIG },
    provideRouter(
      appRoutes,
      withViewTransitions(),
      withComponentInputBinding(),
      withEnabledBlockingInitialNavigation(),
      withDebugTracing(),
      inMemoryScrollingFeature,
     // withHashLocation() //comment this for prod
    ),
    // provideClientHydration(),
    // provideHttpClient(withFetch()),
    // provideAnimations(),
    { provide: Document, useExisting: DOCUMENT },
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHttpErrorHandler,
      multi: true,
    },
    provideStore(
      {},
      {
        // runtimeChecks: {
        //   strictStateImmutability: true,
        //   strictActionImmutability: true,
        //   strictStateSerializability: true,
        //   strictActionSerializability: true,
        //   strictActionWithinNgZone: true,
        //   strictActionTypeUniqueness: true
        // },
        metaReducers: metaReducers,
      }
    ),
    provideEffects(),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic'
      }
    }
    
  ],
};
