import { Component, inject, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { BackendErrorsInterface } from '@ushauri/backend-error-messages';

import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
//import { GeneralSubscriptionService } from './services/general-subscription.service';
import { selectIsSubmittingAddGeneralSubscription, selectValidationErrors } from './store/reducers';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { generalSubscriptionActions } from './store/actions/general-subscription.actions';
import { FormFieldConfig, GeneralFormComponent,SubscribeCardComponent } from '@ushauri/shared-ui';


@Component({
  selector: 'lib-general-subscription',
  standalone: true,
  templateUrl: './general-subscription.component.html',
  styleUrl: './general-subscription.component.scss',  
  imports: [CommonModule,
    ReactiveFormsModule,
    MatCardModule,
    FormsModule,
    MatFormFieldModule,
    GeneralFormComponent,
    SubscribeCardComponent
  ],
})
export class GeneralSubscriptionComponent {

  private fb = inject(FormBuilder);
  private store = inject(Store);
  //private subscriptionService = inject(GeneralSubscriptionService);

  subscriptionForm: FormGroup = this.fb.group({
  //  name: ['', [Validators.required, Validators.minLength(2)]],
    email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    //subscription: ['', [Validators.required, Validators.maxLength(500)]],
    alertType: 'jobAlert'
  });

  // Signals for form state management
  isSubmitting: WritableSignal<boolean> = signal(false);
  submissionStatus: WritableSignal<BackendErrorsInterface | null> = signal(null);
    
  invalid: string[] = [];

  constructor() {
    // Subscribe to the store's state using selectors and update signals
    this.store.select(selectIsSubmittingAddGeneralSubscription).subscribe(isSubmitting => this.isSubmitting.set(isSubmitting));
    this.store.select(selectValidationErrors).subscribe(status => this.submissionStatus.set(status));
  }

  userFormConfig: FormFieldConfig[] = [
    // { name: 'summary', label: 'Summary', type: 'text', placeholder: 'Enter your summary', 
    //   validation: [Validators.required, Validators.minLength(5), Validators.maxLength(200)], 
    //   errorMessages: { required: 'Summary is required.' } }, 
    { name: 'email', label: 'Email', type: 'email', placeholder: 'Enter your email',
      validation: [Validators.required, Validators.email],
      errorMessages: { required: 'Email is required.', email: 'Invalid email address.' } }, 
    // { name: 'name', label: 'Name', type: 'text', placeholder: 'Enter your username',
    //   validation: [Validators.required], errorMessages: { required: 'Username is required.' } }, 
    // { name: 'feedback', label: 'Feedback', type: 'text',
    //   placeholder: 'Enter job title', validation: [Validators.required],
    //    errorMessages: { required: 'Job Title is required.' } }, 
  ];

  formLayoutConfig = {
    // formStyle: { maxWidth: '600px', margin: 'auto' },
    formClass: 'form-horizontal'
  };

  userInfo: string ='userInfo';
  label: string ='Summary';

 initialValues = {
    //candidateId: [this.loggedInUser?.userId],
    //userIfo: {summary: 'ok , its interesting'},
    summary:"lllll",
    //summary: "smsmmsmsmsm",
    candidateId:"kkkkkk",       
  }


  onSubmit(): void {
    if (this.subscriptionForm.valid) {
      // Dispatch the action to submit subscription
      this.store.dispatch(generalSubscriptionActions.addGeneralSubscription({ request: {
        generalSubscription: this.subscriptionForm.value} }));
   
      // this.store.dispatch(manageCandidateProfileActions.editCandidateProfile({
      //   request: {
      //     candidateProfile: completeUserProfile
      //   }
      // }
   
   
    }
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.subscriptionForm.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }
    this.subscriptionForm.markAllAsTouched();
    this.invalid = invalid;
    return invalid;
  }
}




