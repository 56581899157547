<!-- <form class="search-form-container">
    <mat-form-field appearance="outline">
      <input type="email" matInput placeholder="Enter your email">
      <button mat-flat-button class="subscribe-btn">Basic</button>
    </mat-form-field>
  </form>

  <mat-form-field appearance="outline">
    <mat-label>Search</mat-label>
    <input matInput  placeholder="Type something...">
    <button mat-icon-button matSuffix>
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field> -->
<!-- <form class="search-form-container subscribe-footer subscribe-footer--primary bg-major-tertiary"> -->
  <!-- <form class="search-form-container">
  <mat-form-field appearance="outline">
    <mat-label>Subscribe</mat-label>
    <input matInput  placeholder="Enter email">
    <button mat-icon-button matSuffix class="subscribe-btn">
     Subscribe
    </button>
  </mat-form-field>
  </form> -->
  <!-- {{parentFormGroup.value | json}} -->
  <div class="search-form-container">
    <mat-form-field appearance="outline">
      <mat-label>Subscribe</mat-label>
      <input matInput   [formControlName]="'email'" placeholder="Enter email">
      <button mat-icon-button matSuffix class="subscribe-btn" [disabled]="!parentFormGroup.valid">
       <!-- Subscribe {{parentFormGroup.valid}} -->
        Subscribe
      </button>
    </mat-form-field>
  </div>

  <!-- <div class="search-form-container">
    <mat-form-field appearance="outline">
      <mat-label>Outline form field</mat-label>
      <input matInput placeholder="Placeholder">
      <mat-icon matSuffix>search</mat-icon>
      <mat-hint>Hint</mat-hint>
    </mat-form-field>    
 </div>  -->
  
