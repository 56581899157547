import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { SubscribeFormComponent } from '../subscribe-form/subscribe-form.component';


@Component({
  selector: 'rka-subscribe-card',
  standalone: true,
  templateUrl: './subscribe-card.component.html',
  styleUrl: './subscribe-card.component.scss',
  imports: [CommonModule, MatCardModule, SubscribeFormComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscribeCardComponent {
  lower = true;
}
