import { KeycloakConfig } from "keycloak-js";
import { inject, InjectionToken } from "@angular/core";
import { Injectable, Inject } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';


export const APP_KEYCLOAK_CONFIG = new InjectionToken<KeycloakConfig>('keycloak.config');

const Keycloak = typeof window !== 'undefined' ? import('keycloak-js') : null;


@Injectable({
  providedIn: 'root'
})
export class KeycloakInitService {

    private keycloakService = inject(KeycloakService);

  constructor(
    //private keycloakService: KeycloakService,
    @Inject(APP_KEYCLOAK_CONFIG) private keycloakConfig: KeycloakConfig
  ) {}

  public init() {
    return  () => this.initializeKeycloak(this.keycloakService,
      this.keycloakConfig);      
  }

 public  initializeKeycloak(keycloak: KeycloakService, keycloakConfig: KeycloakConfig) {
    if (Keycloak !== null) {
    return () =>
      keycloak.init({
        config: keycloakConfig,
        loadUserProfileAtStartUp: true, 
        initOptions: {
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri:
            window.location.origin + '/assets/silent-check-sso.html'
        }
      });
    } else {
      return () => {
        return new Promise<Boolean>((resolve, reject) => {
          resolve(true);
        });
      };
    }
  }
}

