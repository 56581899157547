import { Inject, Injectable } from '@angular/core';
import { KeycloakProfile } from "keycloak-js";
import { AuthService } from "@ushauri/auth";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../AppConfig/appconfig.interface";
//import { APP_SERVICE_CONFIG } from "../AppConfig/appconfig.service";
//import { UserProfile } from '../model/userprofile.model';
import { Observable, catchError, of, tap } from 'rxjs';
//import { GamesUser } from '../model/gamesuser.model';
import { APP_CONFIG } from '../AppConfig/appconfig.service';
// import { User } from '../model/user.model';
// import { AddCandidateProfileRequest } from '../user-profile/types/addCandidateProfileRequest';
// import { EditCandidateProfileRequest } from '../user-profile/types/editCandidateProfileRequest';


@Injectable({ providedIn: 'root' })
export class UserProfileService {
    

    //currentUser: KeycloakProfile;

     private config : AppConfig = APP_CONFIG;

    constructor(
        private authenticationService: AuthService, private http: HttpClient
    ) {

        //this.config = config;
        // this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

        //this.currentUser = this.authenticationService.loadUserProfile().then();

        // if (!localStorage.getItem('firstReload') || localStorage.getItem('firstReload') == 'true') {
        //   localStorage.setItem('firstReload', 'false');
        //   window.location.reload();
        // } else {
        //   localStorage.setItem('firstReload', 'true');
        // }
    }

/*
    getLoggedInUserDetails() : User{

    const user = {

    }
    return user;
    }


    getUserDetails() {

        // var profile = {
        //     username:"musa",
        //     firstname:"juma"

        // };
        // return profile;

        return this.authenticationService.loadUserProfile();

        //return this.currentUser;
    }

    getLoggedInUser() {

        // var profile = {
        //     username:"musa",
        //     firstname:"juma"

        // };
        // return profile;

        return this.authenticationService.getLoggedInUser();

        //return this.currentUser;
    }

    async getUserEmail(){
        return await this.authenticationService.getUserEmail();
    }


    getLoggedInUserToken() {
        return this.authenticationService.getLoggedInUserToken();
    }

    createUserProfile(userProfile: any): Observable<String> {
       // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>" + JSON.stringify(userProfile));
        //return this.http.post<UserProfile>(this.config.API_URL+'/user-service/users/complete-profile', userProfile);

        return this.http.post<String>(this.config.API_URL + '/user-service/users/api/user/create-user-profile', userProfile);

    }*/

  /*  getFullUserProfile(id: string): Observable<UserProfile> {

        const userprofile: UserProfile = {
            email: "full email",
            firstName: "full firstName",
            lastName: "full lastname",
            userId: "full someid",
            dob: "full somedob",
            userName: " full someusername",
            country: "full somecountry"
        }

        return of(userprofile);
    }
*/
    public getUserName(){
        return this.authenticationService.getUserName();
    }

    postUserProfile(userToCreate: any): Observable<any> {

        //return this.http.post<GamesUser>('https://jsonplaceholder.typicode.com/users', userToCreate);

        return this.http.post<any>(this.config.API_URL + '/user-service/users/api/user/create-user-profile', userToCreate);

    }

    getCompleteUserProfileReallll(): Observable<any> {

        //return of(this.getFakeCandidateProfile());

       // return this.getGamesUser();

      //  return this.http.get<any>(this.config.API_URL + '/user-service/users/api/user/get-complete-profile');
    
    
     return this.http.get<any>(this.config.API_URL + '/user-service/users/get-profile/1d1b02d0-337b-4f12-abe2-0ce7385bad31');
    
    }
    
    getCandidateProfile(candidateId: string) : Observable<any> {
      return this.http.get<any>(this.config.API_URL + `/user-service/users/get-profile/${candidateId}`)
         .pipe(
          tap((res)=> {console.log(res)}),
          catchError((error)=>{
            if(error.status==404)
              return of(undefined)
            else
            throw error;
          }
         ));
  }


    //Move to employerprofile service

    getEmployerProfile(employerId:String): Observable<any> {

        // return this.getGamesUser();
 
         return this.http.get<any>(this.config.API_URL + `/user-service/employers/get-profile/${employerId}`)
         .pipe(
          tap((res)=> {console.log(res)}),
          catchError((error)=>{
            if(error.status==404)
              return of(undefined)
            else
            throw error;
          }
         ));
     }

    //End move


    getCompleteUserProfileById(userDetail:any): Observable<any> {

        // return this.getGamesUser();
 
         return this.http.get<any>(this.config.API_URL + '/user-service/users/api/user/get-complete-profile');
     }
 


    getCompleteUserProfile(): Observable<any> {

       // return this.http.get<any>(this.config.API_URL + '/user-service/users/api/user/get-complete-profile');


       const staticUser = {
        "profileId" : "dosantos-profile-123",
        "user" : {
          "userId" : "887898",
          "userName" : "dosantos",
          "email" : "dosantoes@gm.com",
          "userCreationStatus" : "TEST_CREATED"
        },
        "address" : [ {
          "street" : null,
          "suite" : "Suite 5",
          "city" : "Nairobi",
          "zipcode" : "254",
          "current" : true
        }, {
          "street" : null,
          "suite" : "Suite 7",
          "city" : "Kampala",
          "zipcode" : "255",
          "current" : false
        } ],
        "contacts" : [ {
          "type" : "mobile",
          "value" : "784784784784"
        } ],
        "education" : [ {
          "institution" : "Makerere",
          "level" : "Post Graduate",
          "description" : "Some Fancy Course",
          "fromDate" : [ 2023, 5, 20, 13, 2, 8, 348425000 ],
          "toDate" : [ 2023, 5, 20, 13, 2, 8, 348425000 ]
        } ],
        "workExperience" : [ {
          "organization" : "Mukono Ltd",
          "jobTitle" : null,
          "fromDate" : [ 2023, 5, 20, 13, 2, 8, 350424400 ],
          "toDate" : [ 2023, 5, 20, 13, 2, 8, 350424400 ],
          "description" : "Doing Stuff",
          "current" : true
        } ]
      };


        return of(staticUser);

       //return this.getGamesUser();
    
    }


    getFakeCandidateProfile(){

     const   fakeCandidateProfile = {
            profileId: null,
            user: {
              userId: '1d1b02d0-337b-4f12-abe2-0ce7385bad31',
              userName: 'testuser1',
              email: 'testuser1@ridhika.com',
              profileCreationStatus: 'COMPLETED'
            },
            addresses: [
              {
                street: 'Nairobi',
                suite: 'Suite 5',
                city: '254',
                zipcode: 'Street1',
                current: true
              },
              {
                street: 'Kampala',
                suite: 'Suite 7',
                city: '255',
                zipcode: 'Street2',
                current: false
              }
            ],
            contacts: [
              {
                type: 'mobile',
                value: '784784784784'
              },
              {
                type: 'home',
                value: '000077777'
              }
            ],
            educations: [
              {
                institution: 'Some Fancy Course',
                level: 'Makerere',
                description: 'PostGraduate',
                fromDate: [
                  2024,
                  5,
                  29,
                  13,
                  30,
                  58,
                  448924500
                ],
                toDate: [
                  2024,
                  5,
                  29,
                  13,
                  30,
                  58,
                  448924500
                ]
              },
              {
                institution: 'Just a course',
                level: 'Nairobi',
                description: 'UnderGraduate',
                fromDate: [
                  2024,
                  5,
                  29,
                  13,
                  30,
                  58,
                  448924500
                ],
                toDate: [
                  2024,
                  5,
                  29,
                  13,
                  30,
                  58,
                  448924500
                ]
              }
            ],
            workExperiences: [
              {
                organization: 'Mukono Ltd',
                jobTitle: 'Doing Stuff',
                fromDate: [
                  2024,
                  5,
                  29,
                  13,
                  30,
                  58,
                  449925700
                ],
                toDate: [
                  2024,
                  5,
                  29,
                  13,
                  30,
                  58,
                  449925700
                ],
                description: 'sinikazi',
                current: true
              },
              {
                organization: 'Another Ltd',
                jobTitle: 'Doing more Stuff',
                fromDate: [
                  2024,
                  5,
                  29,
                  13,
                  30,
                  58,
                  449925700
                ],
                toDate: [
                  2024,
                  5,
                  29,
                  13,
                  30,
                  58,
                  449925700
                ],
                description: 'yayyayay',
                current: true
              }
            ],
            prefferedCategories: [
              'science',
              'music'
            ],
            personalDetails: {
              userID: '1d1b02d0-337b-4f12-abe2-0ce7385bad31',
              email: 'testuser1@ridhika.com',
              firstName: 'testuser1',
              lastName: 'testuser1',
              dob: null,
              userName: 'Front End Fake',
              country: null,
              phoneNo: null,
              phones: null
            }
          };

          return fakeCandidateProfile;
    }


/*
    postActualUserProfile(userToCreate: AddCandidateProfileRequest): Observable<any> {

        //return this.http.post<GamesUser>('https://jsonplaceholder.typicode.com/users', userToCreate);

    return this.http.post<any>(this.config.API_URL + '/user-service/users/post-profile', userToCreate.candidateProfile);

      //return  this.getCompleteUserProfile();

    }

    editActualUserProfile(userToEdit: EditCandidateProfileRequest): Observable<any> {

     return this.http.post<any>(this.config.API_URL + '/user-service/users/edit-profile', userToEdit.candidateProfile);
   
    }*/

  

    ///refactor move to employer profile service
    //EmployerProfile

    postEmployerProfile(employerToCreate: any): Observable<any> {

    return this.http.post<any>(this.config.API_URL + '/user-service/employers/post-profile', 
    employerToCreate.employerProfile);
    
    }

    
     editEmployerProfile(employerToEdit: any): Observable<any> {

      return this.http.post<any>(this.config.API_URL + '/user-service/employers/edit-profile', 
      employerToEdit.employerProfile); 
           
      }

    ////end refactor block


    setActiveRoleChoice(selectedRole: any) : Observable<any>{
        return this.http.post<any>(this.config.API_URL + '/user-service/users/api/user/assign-user-role', selectedRole);
    }

    getActiveRole(){
        return this.http.get<any>(this.config.API_URL + '/user-service/users/api/user/get-active-role-for-user');
    }

    //getCountryList(){}


}

