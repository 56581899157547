import { InjectionToken } from "@angular/core";
import { AppConfig } from "./appconfig.interface";
import { environment } from "../../environments/environment";
// import {environment} from "../../../environments/environment";


export const APP_SERVICE_CONFIG = new InjectionToken<AppConfig>('app.config');

export const APP_CONFIG: AppConfig  = {
    APP_URL: environment.APP_URL,
    API_URL: environment.API_URL,
    AUTH_PROVIDER_URL: environment.AUTH_PROVIDER_URL
};