import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd, NavigationCancel } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationControlService {
    constructor(private router: Router, @Inject(PLATFORM_ID) private platformId: object) {
        this.initialize();
      }
    
      private initialize() {
        if (!isPlatformBrowser(this.platformId)) {
          // If not running in the browser, exit early
          return;
        }
    
        this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd && this.hasStateParamsInUrl()) {
            const href = location.origin +
              location.pathname +
              location.search
                .replace(/code=[^&$]*/, '')
                .replace(/scope=[^&$]*/, '')
                .replace(/state=[^&$]*/, '')
                .replace(/session_state=[^&$]*/, '')
                .replace(/^\?&/, '?')
                .replace(/&$/, '')
                .replace(/^\?$/, '')
                .replace(/&+/g, '&')
                .replace(/\?&/, '?')
                .replace(/\?$/, '') +
              location.hash;
    
            history.replaceState(null, window.name, href);
          }
    
          if (event instanceof NavigationCancel) {
            console.log('Navigation was cancelled:', event.reason);
          }
        });
      }
    
      private hasStateParamsInUrl(): boolean {
        // Now it's safe to access `location`
        return location.search.includes('state=') || location.search.includes('code='); // Example condition
      }


      
// private hasStateParamsInUrl() {
//  return !!(this.route.snapshot.queryParamMap.get('code') ||
//   this.route.snapshot.queryParamMap.get('scope') ||
//   this.route.snapshot.queryParamMap.get('state') ||
//   this.route.snapshot.queryParamMap.get('session_state'));
// }
}
