import { inject, Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UnauthGuard implements CanActivate { 

  private authService = inject(AuthService);
  private router = inject(Router);

  canActivate() {
    const authenticated = this.authService.isLoggedIn();
        if (!authenticated) {
         
          return true;
        } else {
          // if (this.router.url.includes('/landing')) {
          // this.router.navigate(['/dashboard']);
          // }
          return false;
        }
    }
}
