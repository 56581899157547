<!-- <app-nx-welcome></app-nx-welcome> <router-outlet></router-outlet> -->


<!-- 

@if(isLoggedIn$ | async){
   <rka-main-nav></rka-main-nav>
  }@else {
    <rka-website-nav></rka-website-nav>
  }  -->
 
  <!-- <rka-main-nav></rka-main-nav>  -->

  <!-- <div *ngIf="isLoggedIn$ | async as isLoggedIn; else loggedOut">
    <rka-main-nav></rka-main-nav>
  </div>  
    
    <ng-template #loggedOut>  
        <rka-website-nav></rka-website-nav>
    </ng-template> -->

<!-- 

    <h1>The state outside {{ authState() }}</h1>

<div *ngIf="authState() as state">
  <h1>The state {{ state }}</h1>

  <ng-container *ngIf="state === 'loggedIn'; else loadingOrLoggedOut">
    loggedIn
  </ng-container>

  <ng-template #loadingOrLoggedOut>
    <div *ngIf="state === 'loading'">Loading...</div>
    <ng-container *ngIf="state === 'loggedOut'">
      <h1>The state here buddy {{ state }}</h1>
    </ng-container>
  </ng-template>
</div> -->




    <!-- <h1>
      The state outside  {{authState() }}
     </h1> -->

    <div *ngIf="authState() as state">
       <!-- <h1>
        The state  {{state}}
       </h1> -->

      <ng-container *ngIf="state === 'loggedIn'; else loadingOrLoggedOut">
        <rka-main-nav></rka-main-nav>
      </ng-container>
    
      <ng-template #loadingOrLoggedOut>
        <div *ngIf="state === 'loading'">Loading...

          <mat-spinner></mat-spinner>
<!-- 
          <div class="spinner-container">
            <mat-spinner [diameter]="200" color="primary"></mat-spinner>
          </div> -->
          

        </div>
        <!-- <div *ngIf="state === 'loggedOut'">
          User Logged Out -->
          <ng-container *ngIf="!(state === 'loggedIn') && !(state === 'loading')  ">

            <!-- <h1>
              The state  here buddy {{state}}
             </h1> -->
          <rka-website-nav></rka-website-nav>
          </ng-container>
        <!-- </div> -->
      </ng-template>
    </div>
    