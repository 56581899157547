<!-- feedback-form.component.html -->
<!-- 
{{invalid}}

 <button (click) = "findInvalidControls()">Show Them</button> -->
<mat-card class="general-sub-card">
    <form [formGroup]="subscriptionForm" (ngSubmit)="onSubmit()">

        <rka-subscribe-card></rka-subscribe-card>

        <!-- <lib-general-form [formConfig]="userFormConfig" 
        [layoutConfig]="formLayoutConfig"  [initialValues]="initialValues" [label]="label"></lib-general-form> -->

      <!-- <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" placeholder="Enter your name" />
        <mat-error *ngIf="feedbackForm.get('name')?.hasError('required')">
          Name is required
        </mat-error>
      </mat-form-field>
  
      <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" placeholder="Enter your email" />
        <mat-error *ngIf="feedbackForm.get('email')?.hasError('email')">
          Please enter a valid email
        </mat-error>
      </mat-form-field>
  
      <mat-form-field appearance="fill">
        <mat-label>Feedback</mat-label>
        <textarea matInput formControlName="feedback" rows="5"></textarea>
        <mat-error *ngIf="feedbackForm.get('feedback')?.hasError('required')">
          Feedback is required
        </mat-error>
      </mat-form-field> -->
  
      <!-- <button mat-raised-button color="primary" type="submit" [disabled]="!subscriptionForm.valid || isSubmitting()">
        Subscribe
      </button> -->
  
      <!-- Submission Status -->
      <!-- <div *ngIf="submissionStatus() === 'success'" class="success-message">
        Feedback submitted successfully!
      </div>
      <div *ngIf="submissionStatus() === 'error'" class="error-message">
        Something went wrong. Please try again.
      </div> -->
    </form>
  </mat-card>
  