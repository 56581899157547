import {KeycloakConfig} from "keycloak-js";
import { environment } from "./environment";


const keycloakConfig : KeycloakConfig = {

    url: environment.AUTH_PROVIDER_URL,
    // realm: 'USHAURI_DEV',
    // clientId: 'dev_client1'

    realm: 'Ridhika',
    clientId: 'web_client_1'
};

export default keycloakConfig;