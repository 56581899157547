import { Component, inject, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AsyncPipe } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthService } from '@ushauri/auth';
import {FooterComponent} from '@ushauri/footer';
import {SubscribeCardComponent } from "@ushauri/shared-ui";
import { Router, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { GeneralSubscriptionComponent } from '@ushauri/general-subscription';
import { MatMenuModule } from '@angular/material/menu';
import {MatAccordion, MatExpansionModule} from '@angular/material/expansion';
import { MenuItem } from '../_services/nav-menu.service';

@Component({
  selector: 'rka-website-nav',
  templateUrl: './website-nav.component.html',
  styleUrl: './website-nav.component.scss',
  standalone: true,
  imports: [
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    AsyncPipe,
    FooterComponent,
    SubscribeCardComponent,
    RouterModule,
    FontAwesomeModule,
    GeneralSubscriptionComponent,
    MatMenuModule,
    MatAccordion,
    MatExpansionModule
]
})
export class WebsiteNavComponent implements OnInit{

  faCoffee = faCoffee;

  private breakpointObserver = inject(BreakpointObserver);

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

    links = [
      { name: 'Home', path: '/', isActive: true },
      //{ name: 'Jobs', path: '/', isActive: false , fragment: 'jobs'},
      { name: 'Jobs', path: '/jobs', isActive: false},
      { name: 'Recruiter', path: '/recruiter', isActive: false },
      { name: 'About', path: '/about', isActive: false },
      { name: 'Contact', path: '/contactus', isActive: false }
    ];

    publicNavMenu: MenuItem[]  = [     
      {label:'Home',navrouterLink: '/', isActive: true, iconName: 'home'},
      {label:'Jobs',navrouterLink: '/jobs', isActive: false,iconName: 'work'},
      {label:'Recruiter',navrouterLink: '/recruiter', isActive: false, iconName: 'group_search'},
      {label:'About',navrouterLink: '/about', isActive: false, iconName: 'quiz'},
      {label:'Contact',navrouterLink: '/contactus', isActive: false, iconName: 'contact_support'},   
    
    ];

    constructor( private router: Router,private authenticationService: AuthService){}
  ngOnInit(): void {
  
      // this.router.events.subscribe(s => {
      //   if (s instanceof NavigationEnd) {
      //     const tree = this.router.parseUrl(this.router.url);
      //     if (tree.fragment) {
      //       const element = document.querySelector("#" + tree.fragment);
      //       if (element) { element.scrollIntoView(element); }
      //     }
      //   }
      // });
  }

    login() {
      this.authenticationService.login();
      }
      register() {
      this.authenticationService.register();
      }
}
