<footer class="bg-major-primary">
  <div class="footer-container txt-major-white">
      <div class="footer-main">
       <div class="footer-column first-footer-column">
         <div class="footer-logo">
          <img src="../../assets/logos/logo-fitted.png" class="footer-logo-image" placeholder="Ridhika Logo">
         </div> 
         <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.
           Dolor officia at optio excepturi autem neque quod suscipit
            animi error totam perferendis velit, id impedit adipisci 
            sunt consectetur earum nulla expedita.
          </p>   
       </div>
       <div class="footer-column">
        <p class="fs-400">Company</p>
        <p class="footer-link">Careers</p>
        <p class="footer-link">Terms and Conditions</p>
        <p class="footer-link">Partner with us</p>
        <p class="footer-link uppercase">faq</p>
       </div>
       <div class="footer-column">
        <p class="fs-400">Job Seekers</p>
        <p class="footer-link">Create Account</p>
        <p class="footer-link">My Jobs Portal</p> 
        <p class="footer-link">Consulting</p>     
        <p class="footer-link uppercase">faq</p>
       </div>
       <div class="footer-column">
        <p class="fs-400">Recruiters</p>
        <p class="footer-link">Create Account</p>
        <p class="footer-link">Products/Services</p>
        <p class="footer-link">Post a Job</p>
        <p class="footer-link uppercase">faq</p>
       </div>
      </div>
      <div class="footer-bottom">
        <p>&copy; 2024 Fursa. All rights reserved.</p>
        <a href="#" class="footer-social">
          <!-- <fa-icon [icon]="faAddressBook"></fa-icon>
          <fa-icon [icon]="faCircle"></fa-icon> -->
          <fa-icon [icon]="faFacebookF"></fa-icon>
          <!-- <fa-icon [icon]="faFreebsd"></fa-icon> -->      
        </a>
  
        <a href="#" class="footer-social">
          <!-- <fa-icon [icon]="faAddressBook"></fa-icon>
          <fa-icon [icon]="faCircle"></fa-icon> -->
          <fa-icon [icon]="faFacebookF"></fa-icon>
          <!-- <fa-icon [icon]="faFreebsd"></fa-icon> -->      
        </a>
       
        <a href="#" class="footer-social">
          <!-- <fa-icon [icon]="faAddressBook"></fa-icon>
          <fa-icon [icon]="faCircle"></fa-icon> -->
          <fa-icon [icon]="faFacebookF"></fa-icon>
          <!-- <fa-icon [icon]="faFreebsd"></fa-icon> -->      
        </a>
  
        <a href="#" class="footer-social">
          <!-- <fa-icon [icon]="faAddressBook"></fa-icon>
          <fa-icon [icon]="faCircle"></fa-icon> -->
          <fa-icon [icon]="faFacebookF"></fa-icon>
          <!-- <fa-icon [icon]="faFreebsd"></fa-icon> -->      
        </a>
       
       <i class="fa-brands fa-square-x-twitter"></i>
  
        <!-- <i style="width: 300px;" class="fa fa-arrow-circle-right"></i> -->
      </div> 
    </div>
    </footer>
    <!-- <div class="social-link-section">
        @for(i of numbers;track i){
            <rka-social-link [socialLinkItem]="socialLinkItem"></rka-social-link>
        }

    </div> -->
   