// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = 
{
  //   production: false,
  //   APP_URL: 'http://localhost:4200',   
  //   API_URL: 'http://localhost:9393',  
  //  // API_URL: 'http://localhost:9997',
  //  AUTH_PROVIDER_URL: 'http://localhost:8087',
  //  AUTH_PROVIDER_REDIRECT_URL: 'http://localhost:4200',  
  //  idleConfig: { idle: 10, timeout: 60, ping: 10 },

   
   //AUTH_PROVIDER_URL: 'http://192.168.0.10:8087'

   //APP_URL: 'http://dev-host:4200',
   //API_URL: 'http://dev-host:9393',
   //AUTH_PROVIDER_URL: 'http://dev-host.com/auth'


    production: true,
    APP_URL: 'https://ridhika.com',   
    API_URL: 'https://ridhika.com/api',  
   // API_URL: 'http://localhost:9997',
   AUTH_PROVIDER_URL: 'https://ridhika.com:8443',
  AUTH_PROVIDER_REDIRECT_URL: 'https://ridhika.com',
  idleConfig: { idle: 10, timeout: 60, ping: 10 },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
