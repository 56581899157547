<div class="role-choice-container">
    <!-- <div mat-dialog-title class="role-choice-header">Awesome!!</div>
    <div mat-dialog-content class="role-content-area">
        Now Select Role! This is important
    </div> -->
    
    <div mat-dialog-actions class="action-options-area">
        <div class="row">
            <div class="column">
            <!-- <button mat-button class="employer-choice" (click)="onClick('JOB_ADMINISTRATOR')"> -->
            <button mat-button class="employer-choice" (click)="onClick('INTERNSHIP_ADMINISTRATOR')">
               <p>Continue</p>
                <p>as</p>
              
               EMPLOYER         
            </button>
            </div>
            <div class="column">
            <button mat-button class="candidate-choice" (click)="onClick('APPLICANT_INTERN')">
               <p>Continue</p>
               <p>as</p>
               CANDIDATE            
            </button>
            </div>
          </div>
    </div>
    </div>