import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID, WritableSignal } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, Router, RouterModule } from '@angular/router';
import { NxWelcomeComponent } from './nx-welcome.component';
import { WebsiteNavComponent } from "./website-nav/website-nav.component";
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '@ushauri/auth';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { MainNavComponent } from "./main-nav/main-nav.component";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'; 
import { NavigationControlService } from './_services/navigation-control.service';

@Component({
  standalone: true,
  selector: 'rka-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [NxWelcomeComponent, RouterModule, 
    WebsiteNavComponent,
     CommonModule,
     MainNavComponent,
     MatProgressSpinnerModule]
})
export class AppComponent implements OnInit, AfterViewChecked {

  title = 'ushauri-web-frontend';

  // isLoggedIn$: BehaviorSubject<boolean>;

  // isLoggedIn$!: Observable<boolean>;

  // constructor(private authenticationService: AuthService){

  // }

  // ngOnInit(): void {
  //   this.isLoggedIn$ = new BehaviorSubject<boolean>(this.authenticationService.isLoggedIn());
  // }

  authState: WritableSignal<'loading' | 'loggedIn' | 'loggedOut'>;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, 
  private authService: AuthService, private cdr: ChangeDetectorRef,
  private readonly router: Router, private readonly route: ActivatedRoute,private navigationControlService: NavigationControlService) {    


    console.log(this.navigationControlService);
  //   this.router.events.subscribe(event => {
  //     if (event instanceof NavigationEnd && this.hasStateParamsInUrl()) {
  //         const href =
  //           location.origin +
  //           location.pathname +
  //           location.search
  //             .replace(/code=[^&$]*/, '')
  //             .replace(/scope=[^&$]*/, '')
  //             .replace(/state=[^&$]*/, '')
  //             .replace(/session_state=[^&$]*/, '')
  //             .replace(/^\?&/, '?')
  //             .replace(/&$/, '')
  //             .replace(/^\?$/, '')
  //             .replace(/&+/g, '&')
  //             .replace(/\?&/, '?')
  //             .replace(/\?$/, '') +
  //           location.hash;

  //       history.replaceState(null, window.name, href);
  //     }
  //    // if (event instanceof NavigationCancel) debugger;

  //     if (event instanceof NavigationCancel) {
      
  //       console.log('Navigation was cancelled:', event.reason);
      
  //        //debugger;
  //     }
  // })

    
    
    // Simulating the async auth state behavior.
    this.authState = this.authService.getAuthState();

    if (isPlatformBrowser(this.platformId)) {
    this.authService.isUserLoggedIn();
    }
  }




// private hasStateParamsInUrl() {
//  return !!(this.route.snapshot.queryParamMap.get('code') ||
//   this.route.snapshot.queryParamMap.get('scope') ||
//   this.route.snapshot.queryParamMap.get('state') ||
//   this.route.snapshot.queryParamMap.get('session_state'));
// }

  ngOnInit(): void {
    //this.authState.set('loading');
    //this.authService.isUserLoggedIn();
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges(); // Ensure UI updates properly
  }

}
