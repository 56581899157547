import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlContainer, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'rka-subscribe-input',
  standalone: true,
  templateUrl: './subscribe-input.component.html',
  styleUrl: './subscribe-input.component.scss',
  viewProviders: [
    {
    provide: ControlContainer,
    useFactory: () => inject(ControlContainer, {skipSelf: true})
    }
  ],
  imports: [CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule],
})
export class SubscribeInputComponent {

  parentContainer = inject(ControlContainer);

  get parentFormGroup(){
    return this.parentContainer.control as FormGroup;
  }
}
