import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import  {SocialLinkItem ,SocialLinkComponent} from '@ushauri/ui-components';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faFreebsd } from '@fortawesome/free-brands-svg-icons';


@Component({
  selector: 'rka-footer',
  standalone: true,
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  imports: [FontAwesomeModule, SocialLinkComponent],
    //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  faAddressBook = faAddressBook;
  faFacebookF = faFacebookF;
  faCircle  = faCircle;
  faFreebsd = faFreebsd;
  socialLinkItem : SocialLinkItem = {
    icon: 'facebook icon',
    url: '#'
  }
  numbers =[1,2,3,4];
}
