import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { MenuItem, NavMenuService } from '../../_services/nav-menu.service';
import { UserProfileService } from '../../_services/user-profile.service';
import { RoleSelectionDialogComponent } from '@ushauri/user-profile';
import { User_Role } from '@ushauri/auth';

@Component({
  selector: 'rka-side-nav-menu',
  standalone: true,
  templateUrl: './side-nav-menu.component.html',
  styleUrl: './side-nav-menu.component.scss',
  imports: [MatListModule,
     RouterModule,
      MatIconModule,
      CommonModule,
      //RoleSelectionDialogComponent
    ]
})
export class SideNavMenuComponent  implements OnInit {
  
showInfo(_t15: MenuItem) {
throw new Error('Method not implemented.');
}

userName!: string;
email!: string | undefined;

userNavMenu!: MenuItem[];


constructor(private navmenuService: NavMenuService, private userProfileService: UserProfileService,
  public dialog: MatDialog){

}

ngOnInit(): void {

// alert(this.navmenuService.getUserRole());

// alert(this.navmenuService.getUserRole() === User_Role.Default);

// console.log(this.navmenuService.getUserRole());

// console.log(this.navmenuService.getUserRole() === User_Role.Default);

  // if(this.navmenuService.getUserRole() as User_Role ===User_Role.Default as User_Role){
    if(this.navmenuService.getUserRole() === User_Role.Default){
    this.showRoleSelectionDialog();
  }else{
  this.userNavMenu = this.navmenuService.getMenuForUser();
  this.userName = this.userProfileService.getUserName();
  }
}



getMenuForUser() {
  this.userNavMenu = this.navmenuService.getMenuForUser();
  return this.userNavMenu;
}

showRoleSelectionDialog(){
// const dialogRef = this.dialog.open(RoleSelectionDialogComponent, {
//   data: { CANDIDATE: 'APPLICANT_CANDIDATE', EMPLOYER: 'JOB_ADMINISTRATOR' },
// });

//alert("Tryring to open role selection dialog");

try{
const dialogRef = this.dialog.open(RoleSelectionDialogComponent, {
  data: { CANDIDATE: 'APPLICANT_INTERN', EMPLOYER: 'INTERNSHIP_ADMINISTRATOR' },
  panelClass: 'role-selection-dialog',
});

dialogRef.afterClosed().subscribe(result => {    

  this.userNavMenu = this.navmenuService.getMenuForUserDirect(result.data);

  
  this.userProfileService.setActiveRoleChoice(result.data).subscribe(
    created=>{
      console.log(created);
      this.userNavMenu = this.navmenuService.getMenuForUser();
      location.reload();
    }
  );
  //this.animal = result;
});

}catch(ex)
{

  console.error('An error occurred:', ex);
  throw(ex);
}

}


}

