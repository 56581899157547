<mat-toolbar color="custom-top">
  @if (isHandset$ | async) {
    <button
      type="button"
      aria-label="Toggle sidenav"
      mat-icon-button
      (click)="drawer.toggle()">
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
  }
  <div class="nav-bar-logo-img-landing-new-container">
    <img src="../../assets/logos/logo-fitted.png" class="nav-bar-logo-img-landing-new" placeholder="Ridhika Logo">
  </div> 
<span class="navigation-spacer"></span>
<!-- Color Test -->

    @for (link of links; track link) {  
      <a mat-button class="top-menu-item underline-indicators"  [routerLink]="[link.path]">
        {{ link.name }}
      </a>  
    }
 
      <button mat-mini-fab class="logout-fab" [matMenuTriggerFor]="profileLogoutMenu">
        <mat-icon>face</mat-icon>
      </button>
      <mat-menu #profileLogoutMenu="matMenu" yPosition="below">
        <button mat-menu-item (click)="logOut()">Logout</button>
      </mat-menu>  
<!-- <mat-nav-list>
    <a mat-list-item (click)="logOut()" class="btn-login"><span class="txt-major-tertiary">Log out</span></a>           
</mat-nav-list> -->
</mat-toolbar>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [fixedTopGap]="(isHandset$ | async) ? 56 : 56 "
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    <!-- <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item href="#">Link 1</a>
      <a mat-list-item href="#">Link 2</a>
      <a mat-list-item href="#">Link 3</a>
    </mat-nav-list> -->
    <rka-side-nav-menu></rka-side-nav-menu>
  </mat-sidenav>
  <mat-sidenav-content>


    <div class="content">
      <!-- Add Content Here -->
      <router-outlet></router-outlet>
   </div>
   <lib-general-subscription></lib-general-subscription>
   <!-- <rka-subscribe-card></rka-subscribe-card> -->
   <rka-footer></rka-footer>

    <!-- Add Content Here -->
     <!-- <div class="fake-content" style="height: 900px; background-color: blue;">
      hey man
     </div> -->
     <!-- <router-outlet></router-outlet> -->
     <!-- <rka-subscribe-input></rka-subscribe-input> -->
     <!-- <rka-subscribe-card></rka-subscribe-card>
     <rka-footer></rka-footer> -->
  </mat-sidenav-content>
</mat-sidenav-container>
<!-- <router-outlet></router-outlet> -->