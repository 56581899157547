import { CanMatchFn, Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { inject } from '@angular/core';
import { User_Role } from '../auth.enum';

export const canMatchNotLoggedIn: CanMatchFn = (route, segments) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const authenticated = authService.isLoggedIn();

  if (!authenticated) {
    return true;
  }
  return false;
};

  