
<mat-nav-list>
    <mat-list-item>
        <mat-icon matListItemIcon>dashboard</mat-icon>
       Dashboard
        <!-- <a mat-list-item routerLink={{nav.navrouterLink}}>{{nav.label}}</a> -->
    </mat-list-item>
    @for (nav of userNavMenu; track nav)  {
    <a mat-list-item [activated]="nav.isActive" routerLink={{nav.navrouterLink}}>
        <mat-icon matListItemIcon>{{nav.iconName}}</mat-icon>
        {{nav.label}}
        <!-- <a mat-list-item routerLink={{nav.navrouterLink}}>{{nav.label}}</a> -->
    </a>
    }
</mat-nav-list>
  
