import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { SubscribeInputComponent } from '@ushauri/ui-components';
import { ControlContainer } from '@angular/forms';

@Component({
  selector: 'rka-subscribe-form',
  standalone: true,
  templateUrl: './subscribe-form.component.html',
  styleUrl: './subscribe-form.component.scss',
  viewProviders: [
    {
    provide: ControlContainer,
    useFactory: () => inject(ControlContainer, {skipSelf: true})
    }
  ],
  imports: [CommonModule, MatCardModule, SubscribeInputComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscribeFormComponent {
  isActive = false;
  // @Input() 
}
