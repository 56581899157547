import { Route } from '@angular/router';
import { canMatchCandidateGuard, canMatchNotLoggedIn, canMatchRecruiterGuard, UnauthGuard, userResolver } from '@ushauri/auth';
import { AccessDeniedComponent } from '@ushauri/shared-ui';

export const appRoutes: Route[] = [ 
       { 
       title:'Landing Page',
       path: '',
       redirectTo: 'landing',
       pathMatch: 'full'
     },
     { 
      path: 'login',
      redirectTo: 'landing'        
    },
    { 
      path: 'register',
      redirectTo: 'landing'        
    },
     {
      path: 'landing',
      canActivate: [UnauthGuard],
      //resolve: { currentUser: userResolver},
      loadChildren: () =>
        import('@ushauri/landing').then((m) => m.landingRoutes), 
     }, 
     {
      path: 'recruiter',
      canActivate: [UnauthGuard],
      loadChildren: () =>
        import('@ushauri/landing-recruiter').then((m) => m.landingRecruiterRoutes), 
     }, 
     {
      path: 'dashboard',
      canMatch: [canMatchRecruiterGuard],
      resolve: { currentUser: userResolver},
      loadChildren: () =>
        import('@ushauri/recruiter-home').then((m) => m.recruiterHomeRoutes),   
      },
      {
       path: 'dashboard',
       canMatch: [canMatchCandidateGuard],
       resolve: { currentUser: userResolver},
       loadChildren: () =>
         import('@ushauri/candidate-home').then((m) => m.candidateHomeRoutes),   
      },
      // {
      //   path: 'ui',
      //   loadComponent: () =>
      //     import('@ushauri/shared-ui').then((m) => m.SharedUiComponent),
      // }, 
      {
        path: 'apply',
        canActivate: [UnauthGuard],           
        loadChildren: () =>
          import('@ushauri/public-jobs').then((m) => m.publicJobsRoutes),       
       
      },  
      {
        path: 'job-applications',
        canMatch: [canMatchCandidateGuard],
        loadChildren: () =>
          import('@ushauri/candidate-job-applications').then((m) => m.candidateJobApplicationsRoutes),
      },
      {
        path: 'job-applications',
        canMatch: [canMatchRecruiterGuard],
        loadChildren: () =>
          import('@ushauri/candidate-job-applications').then((m) => m.candidateJobApplicationsRoutes),
      },
      {
        path: 'jobs',  
        canMatch: [canMatchNotLoggedIn],  
        canActivate: [UnauthGuard],
        data: { requiresAuth: false },    
        loadChildren: () =>
        import('@ushauri/public-jobs').then((m) => m.publicJobsRoutes),       
      },
      {
        path:'myjobs',       
        children: [
      {
        path: '',
        canMatch: [canMatchCandidateGuard],
        loadChildren: () =>
          import('@ushauri/candidate-jobs').then((m) => m.candidateJobsRoutes),
      },
      {
        path: '',
        canMatch: [canMatchRecruiterGuard],
        loadChildren: () =>
        import('@ushauri/recruiter-jobs').then((m) => m.recruiterJobsRoutes),       
      },   
    ]
    },
      {
        path: 'profile',
        canMatch: [canMatchCandidateGuard],
        resolve: { currentUser: userResolver},
        loadChildren: () =>
          import('@ushauri/candidate-profile').then((m) => m.candidateProfileRoutes),
      },
      {
        path: 'profile',
        canMatch: [canMatchRecruiterGuard],
        resolve: { currentUser: userResolver},
        loadChildren: () =>
        import('@ushauri/recruiter-profile').then((m) => m.recruiterProfileRoutes),       
      },
      {
        path: 'contactus',      
        loadChildren: () =>
        import('@ushauri/contact-us').then((m) => m.contactUsRoutes),       
      },
      {
        path: 'about',      
        loadChildren: () =>
        import('@ushauri/about').then((m) => m.aboutRoutes),       
      },
      {
        path: 'public-jobs',      
        loadChildren: () =>
        import('@ushauri/public-jobs').then((m) => m.publicJobsRoutes),       
      },
      { path: '**',
        component: AccessDeniedComponent
     }
    //   { path: '**',
    //     component: NotfoundComponent
    //  }
     
];
