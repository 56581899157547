import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, retry, throwError, timer } from "rxjs";

@Injectable()
export class GlobalHttpErrorHandler implements HttpInterceptor{
  
  constructor(){}

  intercept(httpRequest: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
   
    return next.handle(httpRequest).pipe(
      retry({
        count:3,
        delay:(_,retryCount)=>timer(retryCount*1000)
      }
      ),
      catchError(
        err=>{
          console.log("Error handled by HttpInterceptors");
          return throwError(()=>{
            console.log("Error was rethrown");
           return  err;})
        }
      )
    );
  }
}
