<mat-sidenav-container class="sidenav-container" [class.navigation-is-mobile]="isHandset$ | async">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]=false>
      <!-- [opened]="(isHandset$ | async) === false"> -->
    <!-- <mat-toolbar>Menu</mat-toolbar> -->
    <div class="logo-nav-landing">
      <img src="../../assets/logos/logo-fitted.png" class="nav-bar-logo-img-landing" placeholder="Ridhika Logo">
      </div>
    <mat-nav-list>  
      <!-- <button>Test Button</button>     -->
      <!-- <button mat-button>Test Button 2</button>   -->
        <a mat-list-item class="login-side-nav" (click)="login()">
          <div class="login-side-nav-interior">
            <mat-icon aria-hidden="false">login</mat-icon>
            <span>Login</span>
          </div>         
        </a>
        <a mat-list-item class="login-side-nav" (click)="register()">
          <div class="login-side-nav-interior">
          <mat-icon aria-hidden="false">app_registration</mat-icon>
          <span>Register</span>
          </div>
        </a>

        @for (nav of publicNavMenu; track nav)  {
          <a mat-list-item [activated]="nav.isActive" routerLink={{nav.navrouterLink}}>
              <mat-icon matListItemIcon>{{nav.iconName}}</mat-icon>
              {{nav.label}}
              <!-- <a mat-list-item routerLink={{nav.navrouterLink}}>{{nav.label}}</a> -->
          </a>
          }

        <!-- <button mat-button [matMenuTriggerFor]="afterMenu">After</button>
        <mat-menu #afterMenu="matMenu" xPosition="after">
          <button mat-menu-item>Item 1</button>
          <button mat-menu-item>Item 2</button>
        </mat-menu>           -->

    </mat-nav-list>

    <!-- <mat-expansion-panel-header>
      <mat-panel-title>
        Menu 1
      </mat-panel-title>
      <mat-icon>expand_more</mat-icon>
    </mat-expansion-panel-header> -->
    

      <div class="mat-nav-list-footer">
        <p class="p-small">Copyright ©Fursa 2024</p>
      </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="custom-top">
      @if (isHandset$ | async) {
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="drawer.toggle()">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
      }
      <div class="nav-bar-logo-img-landing-new-container">
        <img src="../../assets/logos/logo-fitted.png" class="nav-bar-logo-img-landing-new" placeholder="Ridhika Logo">
      </div>    
      @if (isHandset$ | async) {
      }      
      @else {
      <span class="navigation-spacer"></span>
      <!-- <a [routerLink]="['somepath']" fragment="Test">Jump to 'Test' anchor </a> -->
    @for (link of links; track link) { 
      <!-- @if(link.fragment){ -->
      <!-- <a mat-button class="top-menu-item underline-indicators"  [routerLink]="[link.path]" fragment=" {{link.fragment}}">
        {{ link.name }}
      </a> -->
      <!-- <a mat-button class="top-menu-item underline-indicators" [routerLink]="[link.path]" [fragment]="link.fragment">
        {{ link.name }}
      </a> -->
      
    <!-- }@else{ -->
      <a mat-button class="top-menu-item underline-indicators"  [routerLink]="[link.path]">
        {{ link.name }}
      </a> 
    <!-- }   -->
    } 
    
      <!-- <mat-nav-list>            -->
    <a mat-button (click)="register()" class="btn-register">Register</a>
    <a mat-button (click)="login()" class="btn-login"><span class="txt-major-tertiary">Login</span></a> 
    
  
      <!-- </mat-nav-list> -->

      }
      
    </mat-toolbar>
    <div class="content">
       <!-- Add Content Here -->
       <!-- <fa-icon [icon]="faCoffee"></fa-icon> -->
       <router-outlet></router-outlet>
    </div>

    <lib-general-subscription></lib-general-subscription>
    <!-- <rka-subscribe-card></rka-subscribe-card> -->
    <rka-footer></rka-footer>
  
     <!-- <rka-search-input></rka-search-input>
     <rka-subscribe-input></rka-subscribe-input> -->
     <!-- <rka-subscribe-form></rka-subscribe-form> -->
     <!-- <rka-subscribe-card></rka-subscribe-card>
     <rka-footer></rka-footer> -->
  </mat-sidenav-content>
</mat-sidenav-container>
