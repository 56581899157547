import { ActionReducer, MetaReducer } from '@ngrx/store';

// console.log all actions



export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    console.log('state before: ', state);
    console.log('action: ', action);
    //return reducer(state, action);
    // Execute the reducer
    const nextState = reducer(state, action);

    console.log('state after: ', nextState);

    return nextState;

  };
}

export const metaReducers: MetaReducer<any>[] = [debug];
