<mat-card class="custom-no-border-card">
    <mat-card-content class="subscribe-footer subscribe-footer--custom">
        <div class="outer-form-container">
        <rka-subscribe-form></rka-subscribe-form>
        </div>
    </mat-card-content>
</mat-card>

<!-- <div class="subscribe-footer subscribe-footer--custom">
        <div class="outer-form-container">
        <rka-subscribe-form></rka-subscribe-form>
        </div>
</div> -->



  
  
