import { Component, inject } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { RouterModule } from '@angular/router';
import { SideNavMenuComponent } from "./side-nav-menu/side-nav-menu.component";
import { AuthService } from '@ushauri/auth';
import { FooterComponent } from "@ushauri/footer";
import { SubscribeInputComponent} from "@ushauri/ui-components";
import {SubscribeCardComponent} from '@ushauri/shared-ui';
import { MatMenuModule } from '@angular/material/menu';
import { GeneralSubscriptionComponent } from '@ushauri/general-subscription';


@Component({
  selector: 'rka-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrl: './main-nav.component.scss',
  standalone: true,
  imports: [
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    AsyncPipe,
    RouterModule,
    SideNavMenuComponent,
   // NgIf,
    FooterComponent,
   // SubscribeInputComponent,
   // SubscribeCardComponent,
    MatMenuModule,
    GeneralSubscriptionComponent
]
})
export class MainNavComponent {

  private breakpointObserver = inject(BreakpointObserver);

  private authenticationService = inject(AuthService);

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

    links = [
      { name: 'Home', path: '/dashboard', isActive: true },
      { name: 'Profile', path: '/profile', isActive: false },
      { name: 'Jobs', path: '/myjobs', isActive: false },     
      { name: 'Contact', path: '/contactus', isActive: false }
    ];


    logOut() { 
      this.authenticationService.logout();
    }
}
  

