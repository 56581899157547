import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-role-selection-dialog',
  standalone: true, 
  templateUrl: './role-selection-dialog.component.html',
  styleUrl: './role-selection-dialog.component.scss',
  imports: [MatDialogModule, MatButtonModule],
  styles: [`
   :host {
    display: block;
    background: #fff;
    border-radius: 20px;
    padding: 10px;
  }
`]
})
export class RoleSelectionDialogComponent {

createRole = "create";

constructor(public dialogRef: MatDialogRef<unknown>,
@Inject(MAT_DIALOG_DATA) public data: any,
) {

dialogRef.disableClose = true;
}

onClick(roleClicked:any): void {


this.dialogRef.close();

this.dialogRef.close({event:this.createRole,data:roleClicked});
}

}
