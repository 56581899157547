import { Injectable } from '@angular/core';
import { AuthService } from '@ushauri/auth';
import { throwError } from 'rxjs';


export interface MenuItem {
  label: string,
  navrouterLink: string,
  isActive: boolean,
  iconName?: string 
};


@Injectable({
  providedIn: 'root'
})
export class NavMenuService {
  
  selectedRole!:string;

//defaultMenuItems: MenuItem[] = [{label:'Home',navrouterLink: '/user'}];

defaultMenuItems: MenuItem[] = [{label:'Home',navrouterLink: '/dashboard', isActive: true},
 {label:'My Profile',navrouterLink: '/profile', isActive: false}];

// menuItems: MenuItem[] = [
//   {label:'Admin',navrouterLink: '/admin'},
//   {label:'Home',navrouterLink: '/user'},
//   {label:'My Profile',navrouterLink: '/user-profile'}];

   candidateMenuItems: MenuItem[] = [     
    {label:'Home',navrouterLink: '/dashboard', isActive: true, iconName: 'home'},     
    {label:'My Profile',navrouterLink: '/profile', isActive: false, iconName: 'person'},
    {label:'Jobs',navrouterLink: '/myjobs', isActive: false,iconName: 'work'},
    {label:'Entry Level Jobs',navrouterLink: '/entry-level-jobs', isActive: false, iconName: 'work_outline'},
    {label:'Subscriptions',navrouterLink: '/payments', isActive: false, iconName: 'subscriptions'},
    {label:'Payment',navrouterLink: '/payments', isActive: false, iconName: 'card_membership'},
    {label:'Applications',navrouterLink: '/job-applications', isActive: false, iconName: 'today'},
  
  ]
    
    ;

    administratorMenuItems: MenuItem[] = [
      //{label:'Admin',navrouterLink: '/admin'},
      {label:'Subscriptions',navrouterLink: '/adminstration-payments', isActive: true},
      {label:'Manage Entry Level Jobs',navrouterLink: '/administration-entry-level-jobs', isActive: false},
      {label:'Manage Jobs',navrouterLink: '/myjobs', isActive: false, iconName: 'dashboard_customize'},
      {label:'Manage Job Applications',navrouterLink: '/manage-job-applications', isActive: false},
      {label:'Home',navrouterLink: '/dashboard', isActive: false},
      {label:'My Profile',navrouterLink: '/profile', isActive: false}];

      candidateAdministratorMenuItems: MenuItem[] = [
        {label:'Subscriptions',navrouterLink: '/payments', isActive: false},
        {label:'Admin',navrouterLink: '/admin', isActive: false},
        {label:'Home',navrouterLink: '/dashboard', isActive: false},
        {label:'My Profile',navrouterLink: '/profile', isActive: false},        
        {label:'Manage Jobs',navrouterLink: '/myjobs', isActive: false}];

constructor(private authenticationService:AuthService) {

 }

 public getMenuForUser(): MenuItem[]{    

  if(this.authenticationService.hasRole('APPLICANT_INTERN')){
    console.warn("applicant!!");
    return this.candidateMenuItems;

  }else if(this.authenticationService.hasRole('INTERNSHIP_ADMINISTRATOR')){
    console.warn("administrator!!");
    return this.administratorMenuItems
  }else{     
   throwError(()=>new Error("Unknown Role for User"));
  }


  //menuItems: MenuItem = [{label:'Home',url: '/user'}];

  return this.defaultMenuItems;

 }


 public getMenuForUserNew(): MenuItem[]{    

  if(this.authenticationService.hasRole('APPLICANT_INTERN')){
    console.warn("applicant!!");
    return this.candidateMenuItems;

  }else if(this.authenticationService.hasRole('INTERNSHIP_ADMINISTRATOR')){
    console.warn("administrator!!");
    return this.administratorMenuItems
  }else{     
   throwError(()=>new Error("Unknown Role for User"));
  }


  //menuItems: MenuItem = [{label:'Home',url: '/user'}];

  return this.defaultMenuItems;

 }



 public getMenuForUserDirect(directRole:any): MenuItem[]{   
  this.selectedRole = directRole;
  if(directRole.toString()==='APPLICANT_INTERN'){  
    return this.candidateMenuItems;
  }else if(directRole.toString()==='INTERSHIP_ADMINISTRATOR'){      
    return this.administratorMenuItems
  }else{
    throwError(()=>new Error("Unknown role"));
  }  
  //menuItems: MenuItem = [{label:'Home',url: '/user'}];
  return this.defaultMenuItems;
 }

public getUserRole() : string {
if(null!=this.selectedRole)
  return this.selectedRole;
 return this.authenticationService.getUserRole(); 
 }
}
