import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
    
    constructor(private snackBar: MatSnackBar, private zone: NgZone){}

    handleError(error: unknown): void {
        this.zone.run(()=>{
        this.snackBar.open(
            'Error occured! We are working on it!',
            'Close',
            {
               duration: 2000
            }
        );
        console.warn('Caught by custom error handler', error);
        if (error instanceof Error) {
        console.error('Error message:', error.message);
        console.error('Stack trace:', error.stack);   
        }
    
    });
    }

}